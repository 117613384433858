import firebase from '../../config/fbConfig'
import { domainToUnicode } from 'url';
import { signOut } from './authActions';
const db = firebase.firestore()


// This algorithm is designed for Teachers and Minor Students
// 1. If the user is a teacher, it only loads the classrooms that the teacher owns, plus Free Books
// 2. If the user is a student, it only loads the classrooms that the student belongs to, plus Free Books


export const getStudentBooks = () => { //THis function made old on 3/16/20
    return (dispatch, getState) => {  //dispatch dispatches an action to the reducer
        const authFB = getState().firebase.auth
        const auth = getState().auth
        // getStudentUser(auth, authFB, (user) => { //This is required becuase if the user refreshes their screen, then the state is refreshed and the user's credentials are lost
            // const user = getState().auth
            let classroom = auth.classroom
            getExpiredBookCodes( (expiredBookCodes) => {
                getPurchaseAccessesStudent(classroom, expiredBookCodes, (purchaseAccesses) => {
                    getBooksFromFBStudent(purchaseAccesses, (purchasedBooks) => {
                        dispatch({ type: "SET_PURCHASED_BOOKS", purchasedBooks })
                    })
                })    
            })
        // })
    }
}

const getExpiredBookCodes = (callback) => {
    let expiredBookCodes = {}
    const queryBookCodes = db.collection("bookCodes").where("disabled", "==", true)
    queryBookCodes.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            //filter out free stuff and iapos classrooms
            expiredBookCodes[doc.id] = true
        })
        callback(expiredBookCodes)
    })
        .catch((error) => {
            console.log("ERROR could not get my classrooms: ", error)
        })
};

// const getStudentUser = (auth, authFB, callback) => {
//     if (!auth.isEmpty) {
//         callback(auth)
//         return
//     }
//     const userDoc = db.collection("usersChild").doc(authFB.uid)
//     const userQuery = collection.where("anonId", "==", authFB.uid)
//     const studentUsers = []
//     userQuery.get().then(function (querySnapshot) {        
//         querySnapshot.forEach(function (doc) {
//                 studentUsers.push({
//                     classroom: classroom,
//                     uidStudent: doc.id, 
//                     ...doc.data()
//                 })
//         })
//         if (studentUsers.length === 1) {
//             callback(studentUsers[0])
//         } else if (studentUsers.length > 1) {
//             callback("error")
//         } else {
//             callback(0)
//         }
//     }).catch(function (error) {
//         console.log("ERROR - could not get student profile for student user login", error)
//     })


//     const userDoc = db.collection("usersChild").doc(authFB.uid)
//     userDoc.get().then(function (doc) {
//         if (doc.exists) {
//             const user = {
//                 uid: doc.id,
//                 ...doc.data()
//             }
//             callback(user)
//         } else {
//             console.log("User does not exist")
//         }
//     }).catch(function (error) {
//         console.log("Error getting user document:", error);
//     });
// }

// const getStudentUser = (credentials, classroom, callback) => {
//     const password = credentials.password
//     const classroomId = classroom.classroomId
//     const collection = db.collection("usersChild");
//     const query = collection.where("classroomId", "==", classroomId)
//                             .where("password", "==", password)
//     const studentUsers = []
//     query.get().then(function (querySnapshot) {        
//         querySnapshot.forEach(function (doc) {
//                 studentUsers.push({
//                     classroom: classroom,
//                     uidStudent: doc.id, 
//                     ...doc.data()
//                 })
//         })
//         if (studentUsers.length === 1) {
//             callback(studentUsers[0])
//         } else if (studentUsers.length > 1) {
//             callback("error")
//         } else {
//             callback(0)
//         }
//     }).catch(function (error) {
//         console.log("ERROR - could not get student profile for student user login", error)
//     })
// }




const getPurchaseAccessesStudent = (classroom, expiredBookCodes, callback) => {
    const collectionPurchaseAccesses = db.collection('purchaseAccess');
    const usageCountField = 'usageCountIdMap.' + classroom.classroomId;
    const queryPurchaseAccess = collectionPurchaseAccesses.where(usageCountField, '>', 0);
    queryPurchaseAccess.get().then( (querySnapshot) => {
        const purchaseAccesses = [];
        querySnapshot.forEach( (doc) => {
            const purchaseAccess = doc.data()

            const paBookCode = purchaseAccess.bookCodeDetails ?
                purchaseAccess.bookCodeDetails.bookCode :
                ""
            const paBookCodeExpired = expiredBookCodes[paBookCode] ? true : false

            let bookIsExpired = true
            let bookCreateTimeSeconds = purchaseAccess["createTime"].seconds
            if (bookCreateTimeSeconds !== undefined) {
                const bookExpirationSeconds = bookCreateTimeSeconds + purchaseAccess["daysPurchased"] * 86400
                const todaySeconds = new Date().getTime() / 1000
                bookIsExpired = todaySeconds > bookExpirationSeconds
            }

            if (!paBookCodeExpired && !bookIsExpired) {
                purchaseAccesses.push({
                    purchaseOrderId: doc.id,
                    ...purchaseAccess
                });    
            }
        })
        callback(purchaseAccesses);
    }, function (err) {
        console.log('COULD NOT RETRIEVE purchaseAccesses: ' + err);
        callback()
    })
};

const getBooksFromFBStudent = (purchaseAccesses, callback) => { //added 1/4/2020
    let books = {}
    const promises = [];
    const collectionBooks = db.collection('books');
    for (var i = 0; i < purchaseAccesses.length; i++) {
        const bookDoc = collectionBooks.doc(purchaseAccesses[i].itemId)
        promises.push(bookDoc.get());
    }
    Promise.all(promises).then( (results) => {
        results.forEach( (doc, index) => {
            books[doc.id] = {
                bookId: doc.id,
                ...doc.data()
            };
        })
        callback(books);
    }, function(err) {
        console.log('COULD NOT RETRIEVE studentBooks: ' + err);
    });    
};

const putBooksInClassroomsStudent = (classroom, books) => {
    classroom.books = []
    console.log("BOOKS STUDENTS")
    console.log(books)
    const theseBooks = Object.values(books)
    theseBooks.forEach( (book) => {
        classroom.books.push(book)
    })
};



// This algorithm is for users who are not minors.
// This retrieves classrooms a user owns and belongs to through entering the class code.
export const getBooks2 = () => {  //STOPPED USING THIS ON 3/6/20. STARTED USING getTeacherBooks INSTEAD.
    //with thunk, we return a function instead of an object with an action
    return (dispatch, getState) => {  //dispatch dispatches an action to the reducer
        const books = {}; //This isn't good. All books from the database get pulled and stored here. Change this so only the books needed get pulled
        let classrooms = [];
        let booksForState = {}; //This object stores only the books that the user has access to. This is done in the putBooksInClassrooms function
        const chapters = {};        
        const authFB = getState().firebase.auth
        const auth = getState().auth

        getUser( auth, authFB, (user) => { //This is required becuase if the user refreshes their screen, then the state is refreshed and the user's credentials are lost
            getBooksFromFB(books, () => {
                const userEmail = user.email
                getClassroomsFromFB_OLD(classrooms, userEmail, (classrooms) => {
                    getPurchaseAccesses(classrooms, () => {
                        putBooksInClassrooms(classrooms, books, booksForState)
                        getChaptersFromFB(chapters, booksForState, () => {
                            //this function puts journals in chapters and returns a classroomProgress object
                            const userId = user.uid
                            getChapterJournals(chapters, userId, (classroomProgress) => {
                                // putJournalsInChapters()
                                const classroomObj = {
                                    // userEmail: userEmail,  //removed 1/13/2020
                                    classrooms: classrooms
                                }
                                addSumOfActivitiesToChapters(chapters);
                                addSumOfActivitiesToBooks(books, chapters);
                                addSumOfActivitiesToClassrooms(classrooms);
                                if (auth.isEmpty) {
                                    const dbUser = user
                                    dispatch({ type: 'LOGIN_SUCCESS', dbUser })    
                                }
                                dispatch({ type: "SET_BOOKS", books })
                                dispatch({ type: "SET_CLASSROOMS", classroomObj })
                                dispatch({ type: "SET_CHAPTERS", chapters })
                                dispatch({ type: "SET_PROGRESS", classroomProgress })
                            })
                        });
                    });
                });
            });    
        })
    };
};


const getUser = (auth, authFB, callback) => {
    if (!auth.isEmpty) {
        callback(auth)
        return
    }
    if (authFB.isAnonymous) {
        //TODO - Continue from here 1/16/2020 7:15am
        getStudentProfileFromAnonId(authFB, (user) => {
            callback(user)
            return
        })
    } else {
        const userDoc = db.collection("users").doc(authFB.uid)
        userDoc.get().then(function(doc) {
            if (doc.exists) {
                const user = {
                    uid: doc.id,
                    ...doc.data()    
                }
                callback(user)
            } else {
                recordUserProfileInFS(authFB, (user) => {
                    callback(user)
                })
            }
        }).catch(function(error) {
            console.log("Error getting user document:", error);
        });
    }
}

//TODO - Continue from here 1/16/2020 7:15am
const getStudentProfileFromAnonId = (authFB, callback) => {
    const userCollection = db.collection("usersChild")
    const userQuery = userCollection.where("anonId", "==", authFB.uid)
    let studentUsers = []
    userQuery.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
            console.log(doc.data())
            studentUsers.push({
                anonId: authFB.uid,
                uidStudent: doc.id,
                ...doc.data()
            })
        })
        if (studentUsers.length > 1) {
            console.log("ERROR - more than one student user found for an anonymous ID")
            callback(false)
        } else if (studentUsers.length === 0){
            console.log("ERROR - no student user found for anonymous id")
            callback(false)
        } else {
            if (!studentUsers[0].isDeleted) {
                getClassroomForStudent(studentUsers[0], (user) => {
                    callback(user)
                })
            } else {
                //Student is deleted
                callback(false)
            }
        }    
    })
    .catch(function (error) {
        console.log("ERROR could not get user object: ", error)
    })
}

const getClassroomForStudent = (studentUser, callback) => {
    let classroom = null
    const classroomRef = db.collection("classrooms_v3").doc(studentUser.classroomId)

    classroomRef.get().then(function (doc) {
        if (doc.exists) {
            classroom = {
                classroomId: doc.id,
                ...doc.data()
            }
            studentUser["classroom"] = classroom
            callback(studentUser)
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }).catch(function (error) {
        console.log("Error getting document:", error);
    });
}

// const signOut = () => {
//     dispatch({ type: 'RESET_BOOKS' })
//     dispatch({ type: 'RESET_CLASSROOMS' })
//     dispatch({ type: 'RESET_CHAPTERS' })
//     const fb = getFirebase();
//     fb.auth().signOut().then(() => {
//         dispatch({ type: 'SIGNOUT_SUCCESS' });
//     });
// }

const recordUserProfileInFS = (authFB, callback) => {
    const user_name = authFB.displayName ?
        authFB.displayName :
        ""
    const userId = authFB.uid
    const newUser = {
        uid:  userId,
        email: authFB.email,
        firstName: user_name,
        lastName: "",
        user_name: user_name,
        role: "student"
    }

    let refUser = db.collection('users').doc(userId);
    refUser.set({
        "createTime": new Date(),
        "email": newUser.email,
        // "role": "Teacher",
        "role": "Student",
        "user_name": newUser.user_name,
        "lastName": newUser.lastName,
        "firstName": newUser.firstName
    }, { merge: true })
        .then(function () {
            console.log("NEW USER recorded in FS");
            callback(newUser)
        })
        .catch(function (error) {
            console.error("Error writing new user in FS: ", error);
        });
}


export const getQuillFile = (quillId) => {
    return (dispatch, getState) => { 
        const refQuill = db.collection("chapter_quillJs").doc(quillId)
        refQuill.get().then(function (doc) {
            if (doc.exists) {
                // console.log("Document data:", doc.data());
                // const quillData = {
                //     quillFiles: {
                //         [quillId]: doc.data()
                //     }
                // }
                const quillData = {
                    [quillId]: doc.data()
                }
                // const quillData = {
                //     quillId,
                //     quillFile: doc.data()
                // }


                dispatch({ type: "SET_QUILLFILE", quillData })
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }
}

const getBooksFromFB = (books, callback) => {
    // const books = {};
    db.collection("books").get().then(function (querySnapshot) {
        // const books = [];
        querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            books[doc.id] = {
                bookId: doc.id,
                ...doc.data()
            };
            // books.push({
            //     bookId: doc.id,
            //     ...doc.data()
            // });
        });
        // console.log("hi there ");
        callback();
    });
};


//This function gets all classrooms where the user is listed as registered student (Gene's old system)
const getClassroomsFromFB_OLD = (classrooms, userEmail, callback) => {
    // console.log(userEmail)
    classrooms = [];
    const collection = db.collection("classrooms_v3");
    const usersFieldPath = new firebase.firestore.FieldPath('registered_students', userEmail);
    const query = collection.where(usersFieldPath, "==", true);
    query.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            if (doc.data().isDeleted) { return }
            classrooms.push({
                classroomId: doc.id,
                ...doc.data()
            });
        });
        callback(classrooms);
    });
};


/*
GET NOTIFIED WHEN MULTIPLE CALLS ARE FINISHED
https://stackoverflow.com/questions/10004112/how-can-i-wait-for-set-of-asynchronous-callback-functions
var promises = [];
for (var i = 0; i < 10; i++) {
    promises.push(doAjax(...));
}
Promise.all(promises).then(function() {
    // returned data is in arguments[0], arguments[1], ... arguments[n]
    // you can process it here
}, function(err) {
    // error occurred
});
*/

const getPurchaseAccesses = (classrooms, callback) => {
    const promises = [];
    const collectionPurchaseAccesses = db.collection('purchaseAccess');
    for (var i = 0; i < classrooms.length; i++) {
        const usageCountField = 'usageCountIdMap.' + classrooms[i].classroomId;
        const queryPurchaseAccess = collectionPurchaseAccesses.where(usageCountField, '>', 0);
        promises.push(queryPurchaseAccess.get());
    }

    Promise.all(promises).then( (results) => {
        // returned data is in arguments[0], arguments[1], ... arguments[n]
        // you can process it here
        results.forEach( (querySnapshot, index) => {
            const purchaseAccesses = [];
            querySnapshot.forEach( (doc) => {
                purchaseAccesses.push({
                    purchaseOrderId: doc.id,
                    ...doc.data()
                });
            })
            classrooms[index].purchaseAccesses = purchaseAccesses;    
        })
        callback();
    }, function(err) {
        console.log('COULD NOT RETRIEVE purchaseAccesses');
    });    
};


const putBooksInClassrooms = (classrooms, books, booksForState) => {
    // console.log("IN REDUCER CLASSROOMS");
    // console.log(classrooms);



    classrooms.forEach( (classroom, i) => {
        if (!classroom.purchaseAccesses) {return} //this line added 1/7/20. should it be removed?
        classroom.purchaseAccesses.forEach( (purchaseAccess) => {
            const book = books[purchaseAccess.itemId];            
            if (book !== undefined) {
                // console.log("BOOK");
                // console.log(book.bookId);
                booksForState[book.bookId] = book;
    
                if (classrooms[i].hasOwnProperty('books')) {
                    classrooms[i].books.push(book);
                } else {
                    classrooms[i].books = [book];
                }                
            } else {
                console.log("COULD NOT FIND BOOK IN mapPurchaseAccesses");
            };    
        });
    });


    //This block was deleted 12/18/2019 at Brooke's request
    //This block added a classroom called "Free Books" for every user of the website
/*
    classrooms.push({
        classroomId: "freeBooks",
        isFreeStuff: true,
        title: "Free Books"
    });
    let freeClassroom = classrooms[(classrooms.length-1)]
    const booksArray = Object.values(books)
    booksArray.forEach( (book) => {
        if (book.isFree) {
            booksForState[book.bookId] = book;
            if (freeClassroom.hasOwnProperty('books')) {
                freeClassroom.books.push(book);
            } else {
                freeClassroom.books = [book];
            }                
        }
    })
*/


    
    /*removed 3/2/19  CAN PROBABLY BE DELETED
    function mapPurchaseAccesses(thisClassroom, index) {   //can define like this if you want the index as well: function getPurchaseAccesses(item, index)
        thisClassroom.purchaseAccesses && thisClassroom.purchaseAccesses.map(purchaseAccess => {
            const thisBook = books[purchaseAccess.itemId];            
            if (thisBook !== undefined) {
                if (classrooms[index].hasOwnProperty('books')) {
                    console.log("CLASSROOM HAS BOOKS");
                    classrooms[index].books.push(thisBook);
                } else {
                    classrooms[index].books = [thisBook];
                }                
            } else {
                console.log("COULD NOT FIND BOOK IN mapPurchaseAccesses");
            };
    
            console.log("BOOKS IN THIS CLASSROOM");
            console.log(classrooms[index].books);

            // classrooms.hasOwnProperty('books')
            //     ? return
            //     : console.log("hi")
        });
    };
*/
};


const getChaptersFromFB = (chapters, booksForState, callback) => {
    const promises = [];
    const collection = db.collection('chapters_v2');
    const bookKeys = Object.keys(booksForState);
    for (var i = 0; i < bookKeys.length; i++) {
        const query = collection.where('bookId', '==', bookKeys[i]);
        promises.push(query.get());
    }
    Promise.all(promises).then( (results) => {
        results.forEach( (querySnapshot, index) => {
            querySnapshot.forEach( (doc) => {
                chapters[doc.id] = {
                    chapterId: doc.id,
                    ...doc.data()
                };
            })
        })
        addNewSectionTitles(chapters);
        callback();
    }, function(err) {
        console.log('COULD NOT RETRIEVE chapters');
    });    
};

const addSumOfActivitiesToChapters = (chapters) => {
    const chapsArr = Object.values(chapters)
    for (let i = 0; i < chapsArr.length; i++) {
        const fileArray = chapsArr[i].file_array ?
            chapsArr[i].file_array :
            []
        let numFiles = 0
        let studentChapFileIds = []
        for (let j = 0; j < fileArray.length; j++) {
            const chapFile = fileArray[j]
            const isStudentFile = !(chapFile.fileAccessLevel && chapFile.fileAccessLevel !== "Student")
            if (isStudentFile) {
                numFiles++
                studentChapFileIds.push(chapFile.fileId)
            }
        }
        chapsArr[i]["numActivities"] = numFiles
        chapsArr[i]["studentChapFileIds"] = studentChapFileIds
    }    
}

const addSumOfActivitiesToBooks = (books, chapters) => {
    const booksArr = Object.values(books)
    for (let i = 0; i < booksArr.length; i++) {
        const book = booksArr[i]
        const chapterOrderArr = book.chapterOrderArr ?
            book.chapterOrderArr :
            []
        let numActivities = 0
        let studentChapFileIds = []
        for (let j = 0; j < chapterOrderArr.length; j++) {
            const numActivitiesOfChapter = chapters[chapterOrderArr[j]] ?
                chapters[chapterOrderArr[j]]["numActivities"] ?
                    chapters[chapterOrderArr[j]]["numActivities"] :
                    0 :
                0
            numActivities = numActivities + numActivitiesOfChapter
            
            const chapFilesOfChapter = chapters[chapterOrderArr[j]] ?
                chapters[chapterOrderArr[j]]["studentChapFileIds"] ?
                    chapters[chapterOrderArr[j]]["studentChapFileIds"] :
                    [] :
                []
            studentChapFileIds = studentChapFileIds.concat(chapFilesOfChapter)
        }
        booksArr[i]["numActivities"] = numActivities
        booksArr[i]["studentChapFileIds"] = studentChapFileIds
    }
}

const addSumOfActivitiesToClassrooms = (classrooms) => {
    const classroomsArr = Object.values(classrooms)
    for (let i = 0; i < classroomsArr.length; i++) {
        const classroom = classroomsArr[i]
        const classroomBooksArr = classroom.books ?
            classroom.books :
            []
        let numActivities = 0
        let studentChapFileIds = []
        for (let j = 0; j < classroomBooksArr.length; j++) {
            const book = classroomBooksArr[j]
            const numActivitiesOfBook = book.numActivities ?
                book.numActivities :
                0
            numActivities = numActivities + numActivitiesOfBook

            const chapFilesOfBook = book.studentChapFileIds ?
                book.studentChapFileIds :
                []
            studentChapFileIds = studentChapFileIds.concat(chapFilesOfBook)
        }
        classroomsArr[i]["numActivities"] = numActivities
        classroomsArr[i]["studentChapFileIds"] = studentChapFileIds
    }
}

const addNewSectionTitles = (chapters) => {
    const fileSections = {
        Introduce: "Vocabulary",
        Interact: "Talk about it",
        Story: "Story, Songs & Culture",
        Play: "Reading & Games",
        Measure: "Classroom & Conversation"

        // Introduce: "Vocabulary",
        // Interact: "About You",
        // Story: "Story",
        // Play: "Read",
        // Measure: "Class",

        // Introduce: "Read",
        // Interact: "Activities",
        // Story: "Extra Resources",
        // Play: "Interview",
        // Measure: "Teacher Files"

        // Introduce: "Day 1: Vocabulary",
        // Interact: "Day 2: About You",
        // Story: "Day 3: Story",
        // Play: "Day 4: Read",
        // Measure: "Day 5: Class",
    }

    // this is implemented in SideNavChapExpand.js
    const fileSectionsForLasLagrimas = {
        Introduce: "Book",
        Interact: "Printables",
        Story: "Interactive",
        Play: "For Conversation",
        Measure: "Measure"
    }

    const chapsArr = Object.values(chapters)
    for (let i = 0; i < chapsArr.length; i++) {
        const bookId = chapsArr[i].bookId ?
            chapsArr[i].bookId :
            ""
        const fileArray = chapsArr[i].file_array
        for (let j=0; j<fileArray.length; j++) {
            const chapFile = fileArray[j]
            const oldFileSection = chapFile["fileSection"] ?
                chapFile["fileSection"] :
                "Introduce"
            let newFileSection = fileSections[oldFileSection]
            // this is implemented in SideNavChapExpand.js
            // if (bookId === "09OnwfkhmKoqnh8YOLKg") { //Las Lágrimas de Xóchitl
            //     newFileSection = fileSectionsForLasLagrimas[oldFileSection]
            // }
            chapFile["fileSectionNew"] = newFileSection
        }
    }
}
const getChapterJournals = (chapters, userIddd, callback) => {
    const promises = []
    let chapterJournals = {}
    let classroomProgress = {}
    const collection = db.collection("chapterJournals")
    const query = collection.where("uid", "==", userIddd)
    const queryProgress = db.collection("classroomProgress").where("uid", "==", userIddd)

    promises.push(query.get())
    promises.push(queryProgress.get())

    Promise.all(promises).then( (results) => {
        results.forEach( (querySnapshot, index) => {
            if (index === 0) {
                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    const journalData = doc.data()
                    // if (journalData["uid"] == userIddd) {
                    // }
                    if (journalData.hasOwnProperty('chapterId')) {
                        chapterJournals[journalData.chapterId] = {
                            chapterJournalId: doc.id,
                            ...journalData
                        };
                    }                
                });
                const chaptersArr = Object.values(chapters)
                chaptersArr.forEach( (chapter) => {
                    chapter["journal"] = chapterJournals[chapter.chapterId]
                })        
            } else {
                querySnapshot.forEach(function (doc) {
                    const progressData = doc.data()
                    const key = progressData.bookId
                    classroomProgress[key] = {
                        progressId: doc.id,
                        ...progressData
                    }
                })
            }
        })
        callback(classroomProgress)
    })
}

//this function marked old right before i changed tracking progress by book and classroom to just by book
const getChapterJournalsOLD = (userIddd, chapters, callback) => {
    const promises = []
    let chapterJournals = {}
    let classroomProgress = {}
    const collection = db.collection("chapterJournals")
    const query = collection.where("uid", "==", userIddd)
    const queryProgress = db.collection("classroomProgress").where("uid", "==", userIddd)

    promises.push(query.get())
    promises.push(queryProgress.get())

    Promise.all(promises).then( (results) => {
        results.forEach( (querySnapshot, index) => {
            if (index === 0) {
                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    const journalData = doc.data()
                    // if (journalData["uid"] == userIddd) {
                    // }
                    if (journalData.hasOwnProperty('chapterId')) {
                        chapterJournals[journalData.chapterId] = {
                            chapterJournalId: doc.id,
                            ...journalData
                        };
                    }                
                });
                const chaptersArr = Object.values(chapters)
                chaptersArr.forEach( (chapter) => {
                    chapter["journal"] = chapterJournals[chapter.chapterId]
                })        
            } else {
                //TODO-AJS FIX THIS!!!!
                querySnapshot.forEach(function (doc) {
                    const progressData = doc.data()
                    const key = progressData.classroomId + progressData.bookId
                    classroomProgress[key] = {
                        progressId: doc.id,
                        ...progressData
                    }
                })
            }
        })
        callback(classroomProgress)
    })
}

 





// export const getStudentBooksOLD = () => { //THis function made old on 3/16/20
//     return (dispatch, getState) => {  //dispatch dispatches an action to the reducer
//         const books = {}; //Only the books the student has access to are downloaded from the database. Contrast this with getBooks2 function
//         let classrooms = [];
//         const chapters = {};        
//         const authFB = getState().firebase.auth
//         const auth = getState().auth

//         getUser(auth, authFB, (user) => { //This is required becuase if the user refreshes their screen, then the state is refreshed and the user's credentials are lost
//             if (!user) {
//                 //If getUser returns false, then the user profile could not be retrieved. Maybe the student was deleted or the query returned multiple profiles
//                 signOut()
//                 return
//             }
//             let classroom = user.classroom
//             classrooms.push(classroom)
//             getPurchaseAccessesStudent(classroom, (purchaseAccesses) => {
//                 getBooksFromFBStudent(classroom, books, () => {
//                     putBooksInClassroomsStudent(classroom, books)
//                     getChaptersFromFB(chapters, books, () => {
//                         const userId = user.uidStudent
//                         getChapterJournals(chapters, userId, (classroomProgress) => {
//                             const classroomObj = {
//                                 classrooms: classrooms
//                             }
//                             addSumOfActivitiesToChapters(chapters);
//                             addSumOfActivitiesToBooks(books, chapters);
//                             addSumOfActivitiesToClassrooms(classrooms);
//                             const studentUser = user
//                             console.log("THIS IS THE USER")
//                             console.log(studentUser)
//                             dispatch({ type: "LOGIN_STUDENT", studentUser });
//                             dispatch({ type: "SET_BOOKS", books })
//                             dispatch({ type: "SET_CLASSROOMS", classroomObj })
//                             dispatch({ type: "SET_CHAPTERS", chapters })
//                             dispatch({ type: "SET_PROGRESS", classroomProgress })
//                         })
//                     })
//                 })
//             })
//         })
//     }
// }
