import React from "react";

export const BrandLogoSmall = () => (
  <img
    className="nav-logo-small"
    src="../../../../img/LaunchStorylabs.png"
    alt=""
  />
);

export const BrandLogoLarge = () => (
  <img
    className="nav-logo nav-logo-lg"
    src="../../../../img/LaunchStorylabs.png"
    alt=""
  />
);
