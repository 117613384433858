import firebase from '../../config/fbConfig'
const db = firebase.firestore()




export const getStudents = (classroomId) => {
    return (dispatch, getState) => {
        let students = {}
        const queryStudents = db.collection("usersChild").where("classroomId", "==", classroomId)
        queryStudents.get().then( (querySnapshot) => {
            querySnapshot.forEach( (doc) => {
                //don't include deleted students
                if (doc.data().deleted) { return }
                students[doc.id] = {
                    studentId: doc.id,
                    ...doc.data()
                }
            })
            const studentsInClass = {
                [classroomId]: students
            }
            dispatch({ type: "SET_STUDENTS_IN_CLASS", studentsInClass})
        })
        .catch( (error) => {
            console.log("ERROR could not get students for classroom: ", error)
        })
    }
};

export const updateStudentsAfterSave = (studentsToAdd) => {
    return (dispatch, getState) => {
        dispatch({ type: "UPDATE_STUDENTS_IN_CLASS", studentsToAdd })
    }
};

export const saveClassCode = (classCodeDetails) => {
    return (dispatch, getState) => {
        //classCodeDetails looks like this: {classroomId: "classroomId"; classCode: "classCode"}
        console.log(classCodeDetails.classroomId)
        const classroomRef = db.collection("classrooms_v3").doc(classCodeDetails.classroomId)
        classroomRef.update({ classCode: classCodeDetails.classCode })
        .then ( () => {
            dispatch({ type: "UPDATE_CLASS_CODE", classCodeDetails })
        })
        .catch ( (error) => {
            console.error("Error updating Class Code: ", error);
        })
    }
};

export const saveNewClassroom = (newClassTitle) => {
    return (dispatch, getState) => {
        const newClassRef = db.collection("classrooms_v3").doc()
        let accessCode = newClassRef.id.slice(-5)
        accessCode = accessCode.toUpperCase()
        const userProfile = getState().auth
        const uid = userProfile.uid
        const userEmail = userProfile.email
        const user_name = userProfile.user_name
        const timeCreate = new Date()
        const newClass = {
            accessCode,
            classroomBookCount: 1,
            registered_students: {
                [userEmail]: true
            },
            studentDetailsMap: {
                [userEmail]: {
                    uid,
                    user_name
                }
            },
            teacherUid: uid,
            timeCreate,
            timeUpdate: new Date(),
            title: newClassTitle
        }

        newClassRef.set(newClass)
        .then( () => {
            const newClassroom = {
                ...newClass,
                classroomId: newClassRef.id
            }
            // dispatch({ type: "CLASS_SET_NEW", newClassroom })
            // dispatch({ type: "SET_NEW_MY_CLASSROOM", newClassroom })
            dispatch({ type: "SET_NEW_CLASSROOM", newClassroom })
        })
        .catch ( (error) => {
            console.log("Error saving new class: ", error)
        })
    }
};

export const deleteClassroom = (classroom) => {
    return (dispatch, getState) => {
        console.log("DELETE CLASSROOM")
        console.log(classroom)
        const classroomRef = db.collection("classrooms_v3").doc(classroom.classroomId)
        classroomRef.update({
            teacherUid: "deleted",
            teacherUidOLD: classroom.teacherUid,
            isDeleted: true
        })
        .then(function() {
            // TODO - continue from here 1/21/20
            dispatch({ type: "DELETE_CLASSROOM_FROM_CLASSREDUCER", classroom })
            dispatch({ type: "DELETE_CLASSROOM_FROM_MYCLASSROOMSREDUCER", classroom })
        })
        .catch(function(error) {
            console.error("Error updating document: ", error);
        });
    }
};




//Functions to retrieve teacher owned books
export const getTeacherBooks = () => {  
    return (dispatch, getState) => {  
        const authFB = getState().firebase.auth
        const auth = getState().auth
        getUser( auth, authFB, (user) => { //This is required becuase if the user refreshes their screen, then the state is refreshed and the user's credentials are lost
            const uid = user.uid
            getMyClassrooms(uid, (classrooms) => {
                getExpiredBookCodes( (expiredBookCodes) => {
                    getPurchaseAccess(uid, expiredBookCodes, (purchaseAccess) => {
                        const paByBookId = getPaByBookId(purchaseAccess)
                        const paByClassroomIdBookId = getPaByClassroomIdBookId(purchaseAccess)
                        getPurchasedBooksDetail(paByBookId, (purchasedBooks) => {
                            if (auth.isEmpty) {
                                dispatch({ type: 'LOGIN_SUCCESS', user })
                            }
                            dispatch({ type: "SET_MY_CLASSROOMS", classrooms })
                            dispatch({ type: "SET_PURCHASEACCESS", purchaseAccess })
                            dispatch({ type: "SET_PA_BYBOOK", paByBookId })
                            dispatch({ type: "SET_PA_BYCLASSROOM_BYBOOK", paByClassroomIdBookId })
                            dispatch({ type: "SET_PURCHASED_BOOKS", purchasedBooks })
                        })
                    })    
                })
            })
        })
    }
};

const getUser = (auth, authFB, callback) => {
    if (!auth.isEmpty) {
        callback(auth)
        return
    }
    const userDoc = db.collection("users").doc(authFB.uid)
    userDoc.get().then(function (doc) {
        if (doc.exists) {
            const user = {
                uid: doc.id,
                ...doc.data()
            }
            callback(user)
        } else {
            recordUserProfileInFS(authFB, (user) => {
                callback(user)
            })
        }
    }).catch(function (error) {
        console.log("Error getting user document:", error);
    });
}

const recordUserProfileInFS = (authFB, callback) => {
    const user_name = authFB.displayName ?
        authFB.displayName :
        ""
    const userId = authFB.uid
    const newUser = {
        uid:  userId,
        email: authFB.email,
        firstName: user_name,
        lastName: "",
        user_name: user_name,
        role: "student"
    }

    let refUser = db.collection('users').doc(userId);
    refUser.set({
        "createTime": new Date(),
        "email": newUser.email,
        // "role": "Teacher",
        "role": "Student",
        "user_name": newUser.user_name,
        "lastName": newUser.lastName,
        "firstName": newUser.firstName
    }, { merge: true })
        .then(function () {
            console.log("NEW USER recorded in FS");
            callback(newUser)
        })
        .catch(function (error) {
            console.error("Error writing new user in FS: ", error);
        });
}

const getMyClassrooms = (userId, callback) => { //copied from myClassroomsActions.js
    let classrooms = {}
    const queryClassrooms = db.collection("classrooms_v3").where("teacherUid", "==", userId)
    queryClassrooms.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            //filter out free stuff and iapos classrooms
            if (!(doc.data().isInAppPurchaseIos || doc.data().isFreeStuff)) {
                classrooms[doc.id] = {
                    classroomId: doc.id,
                    ...doc.data()
                }
            }
        })
        callback(classrooms)
    })
        .catch((error) => {
            console.log("ERROR could not get my classrooms: ", error)
        })
};

const getExpiredBookCodes = (callback) => {
    let expiredBookCodes = {}
    const queryBookCodes = db.collection("bookCodes").where("disabled", "==", true)
    queryBookCodes.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            //filter out free stuff and iapos classrooms
            expiredBookCodes[doc.id] = true
        })
        callback(expiredBookCodes)
    })
        .catch((error) => {
            console.log("ERROR could not get my classrooms: ", error)
        })
};


const getPurchaseAccess = (uid, expiredBookCodes, callback) => {
    let purchaseAccesses = {}
    const queryPurchases = db.collection("purchaseAccess").where("payerUid", "==", uid)
    queryPurchases.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const purchaseAccess = doc.data()

            const paBookCode = purchaseAccess.bookCodeDetails ?
                purchaseAccess.bookCodeDetails.bookCode :
                ""
            const paBookCodeExpired = expiredBookCodes[paBookCode] ? true : false


            let bookIsExpired = true
            let bookCreateTimeSeconds = purchaseAccess["createTime"].seconds
            if (bookCreateTimeSeconds !== undefined) {
                const bookExpirationSeconds = bookCreateTimeSeconds + purchaseAccess["daysPurchased"] * 86400
                const todaySeconds = new Date().getTime() / 1000
                bookIsExpired = todaySeconds > bookExpirationSeconds
            }

            if (!paBookCodeExpired && !bookIsExpired) {
                purchaseAccesses[doc.id] = {
                    purchaseAccessId: doc.id,
                    ...purchaseAccess
                }    
            }
        })
        callback(purchaseAccesses)
    })
    .catch(function(error) {
        console.log("Error getting purchaseAccess: ", error);
    });
}

const getPaByBookId = (purchaseAccesses) => {
    let paByBookId = {}
    const purchaseAccessArr = Object.values(purchaseAccesses)
    purchaseAccessArr.forEach( (purchaseAccess) => {
        const bookId = purchaseAccess.itemId ?
            purchaseAccess.itemId :
            "none"
        if (bookId !== "none") {
            if (paByBookId[bookId] === undefined) {
                paByBookId[bookId] = [ purchaseAccess ]
            } else {
                paByBookId[bookId].push( purchaseAccess )
            }
        }
    })
    return paByBookId
}

const getPaByClassroomIdBookId = (purchaseAccesses) => {
    let paByClassroomIdBookId = {}
    const purchaseAccessArr = Object.values(purchaseAccesses)
    purchaseAccessArr.forEach((purchaseAccess) => {
        const bookId = purchaseAccess.itemId ?
            purchaseAccess.itemId :
            "none"
        if (bookId !== "none") {
            const usageCountIdMap = purchaseAccess.usageCountIdMap ?
                purchaseAccess.usageCountIdMap :
                {}
            const classroomIdsAndCount = Object.entries(usageCountIdMap)
            classroomIdsAndCount.forEach((classroomIdAndCount) => {
                const classroomId = classroomIdAndCount[0]
                const count = classroomIdAndCount[1]
                if (count > 0) {
                    if (paByClassroomIdBookId[classroomId] === undefined) {
                        paByClassroomIdBookId[classroomId] = {
                            [bookId]: [purchaseAccess]
                        }
                    } else {
                        if (paByClassroomIdBookId[classroomId][bookId] === undefined) {
                            paByClassroomIdBookId[classroomId][bookId] = [purchaseAccess]
                        } else {
                            paByClassroomIdBookId[classroomId][bookId].push(purchaseAccess)
                        }
                    }
                }
            })    
        }
    })
    return paByClassroomIdBookId
}

const getPurchasedBooksDetail = (paByBookId, callback) => {    
    let purchasedBooks = {}
    const promises = [];
    const purchasedBookIdsArr = Object.keys(paByBookId)
    purchasedBookIdsArr.forEach( (bookId) => {
        const bookDoc = db.collection("books").doc(bookId)
        promises.push(bookDoc.get())
    })
    Promise.all(promises).then( (results) => {
        results.forEach( (doc, index) => {
            if (doc.exists) {
                purchasedBooks[doc.id] = {
                    ...doc.data(),
                    bookId: doc.id
                }
            }
        })
        callback(purchasedBooks)
    }, function(err) {
        console.log('COULD NOT RETRIEVE purchased books', err);
    })
}



export const addBookToClassroom = (classroomId, bookId) => {
    return (dispatch, getState) => {
        console.log("ADDING")
        console.log(classroomId)
        console.log(bookId)
        const purchaseAccess = getState().books.purchaseAccess
        console.log(getState().books)
        const paByBookId = getState().books.paByBookId
        const thisPurchaseAccess = paByBookId[bookId][0]
        console.log(thisPurchaseAccess)
        thisPurchaseAccess["usageCountIdMap"] = {
            ...thisPurchaseAccess["usageCountIdMap"],
            [classroomId]: 1
        }
        purchaseAccess[thisPurchaseAccess.purchaseAccessId] = thisPurchaseAccess
        console.log(thisPurchaseAccess)
        console.log(getState().books.purchaseAccess)
        const purchaseAccessArr = [thisPurchaseAccess]
        updatePurchaseAccessFS(purchaseAccessArr, () => {
            console.log("UPDATED")
            dispatchPurchaseAccess(dispatch, purchaseAccess)
        })
    }
}

export const removeBookFromClassroom = (classroomId, bookId) => {
    return (dispatch, getState) => {
        const purchaseAccess = getState().books.purchaseAccess
        const paByClassroomIdBookId = getState().books.paByClassroomIdBookId
        const purchaseAccessArr = paByClassroomIdBookId[classroomId][bookId]
        purchaseAccessArr.forEach( (thisPurchaseAccess) => {
            // delete purchaseAccess[classroomId]
            thisPurchaseAccess["usageCountIdMap"] = {
                ...thisPurchaseAccess["usageCountIdMap"],
                [classroomId]: 0
            }
            purchaseAccess[thisPurchaseAccess.purchaseAccessId] = thisPurchaseAccess
        })
        updatePurchaseAccessFS( purchaseAccessArr, () => {
            console.log("UPDATED")
            dispatchPurchaseAccess(dispatch, purchaseAccess)
        })
    }
}

const updatePurchaseAccessFS = (purchaseAccessArr, callback) => {
    // batch write update mulitple documents
    var batch = db.batch();
    purchaseAccessArr.forEach( (purchaseAccess) => {
        const purchaseAccessId = purchaseAccess.purchaseAccessId
        const usageCountIdMap = purchaseAccess.usageCountIdMap
        console.log("SUAGE COUESDFASDFER")
        console.log(usageCountIdMap)
        const purchaseAccessRef = db.collection("purchaseAccess").doc(purchaseAccessId);
        batch.update(purchaseAccessRef, { usageCountIdMap: usageCountIdMap })
    })

    batch.commit()
    .then(function () {
        callback()
    })
    .catch(function (error) {
        console.error("Error updating purchaseAccess: ", error);
    });
}

const dispatchPurchaseAccess = (dispatch, purchaseAccess) => {
    const paByBookId = getPaByBookId(purchaseAccess)
    const paByClassroomIdBookId = getPaByClassroomIdBookId(purchaseAccess)
    dispatch({ type: "SET_PURCHASED_ACCESS", purchaseAccess })
    dispatch({ type: "SET_PA_BYBOOK", paByBookId })
    dispatch({ type: "SET_PA_BYCLASSROOM_BYBOOK", paByClassroomIdBookId })
}



//temp function for adding users to heart curriculum
export const saveNewPaForHeart = (uid) => {  
    return (dispatch, getState) => {  
        const timeCreate = new Date()

        const newClassroomRef = db.collection("classrooms_v3").doc();
        const classroomId = newClassroomRef.id
        const newPaRef7 = db.collection("purchaseAccess").doc()
        const newPaRef8 = db.collection("purchaseAccess").doc()
        const newPaRef9 = db.collection("purchaseAccess").doc()

        const newClassroom = {
            classroomBookCount: 1,
            registered_students: {},
            studentDetailsMap: {},
            teacherUid: uid,
            timeCreate: timeCreate,
            timeUpdate: timeCreate,
            title: "HEART Curriculum"
        }

        const newPaHeart7 = {
            createTime: timeCreate,
            daysPurchased: 365,
            itemId: "qtura7Znn4QUxLpvmyPl",
            payerUid: uid,
            permittedUsers: {},
            purchaseOrderId: "admin generated",
            quantity: 1,
            updatedOn: timeCreate,
            usageCountIdMap: {
                [classroomId]: 1
            }
        }
        const newPaHeart8 = {
            createTime: timeCreate,
            daysPurchased: 365,
            itemId: "2YHgZ3VS6mg4xcEETKX1",
            payerUid: uid,
            permittedUsers: {},
            purchaseOrderId: "admin generated",
            quantity: 1,
            updatedOn: timeCreate,
            usageCountIdMap: {
                [classroomId]: 1
            }
        }
        const newPaHeart9 = {
            createTime: timeCreate,
            daysPurchased: 365,
            itemId: "86eW72JqKgjRF3s7g3NM",
            payerUid: uid,
            permittedUsers: {},
            purchaseOrderId: "admin generated",
            quantity: 1,
            updatedOn: timeCreate,
            usageCountIdMap: {
                [classroomId]: 1
            }
        }

        newClassroomRef.set(newClassroom)
        .then(function () {
            console.log("Saved Classroom")
        })
        .catch(function (error) {
            console.error("Error Classroom", error);
        });
        newPaRef7.set(newPaHeart7)
        .then(function () {
            console.log("Saved PaHeart7")
        })
        .catch(function (error) {
            console.error("Error PaHeart7", error);
        });
        newPaRef8.set(newPaHeart8)
        .then(function () {
            console.log("Saved PaHeart8")
        })
        .catch(function (error) {
            console.error("Error PaHeart8", error);
        });
        newPaRef9.set(newPaHeart9)
        .then(function () {
            console.log("Saved PaHeart9")
        })
        .catch(function (error) {
            console.error("Error PaHeart9", error);
        });

    }
}


const recordInFS = (newUser, callback) => {
    const userId = newUser.uid
    let refUser = db.collection('users').doc(userId);
    const userRecorded = {
        "createTime": new Date(),
        "email": newUser.email,
        "role": "Teacher",
        "user_name": newUser.firstName + " " + newUser.lastName,
        "lastName": newUser.lastName,
        "firstName": newUser.firstName
    }
    refUser.set(userRecorded, { merge: true })
        .then(function () {
            // console.log("NEW USER recorded in FS");
            userRecorded["uid"] = userId
            callback(userRecorded)
        })
        .catch(function (error) {
            console.error("Error writing new user in FS: ", error);
        });
}


export const parseStudentCsv = (file, classroomId) => {
    return (dispatch, getState) => {
        dispatch({ type: "SHOW_SPINNER" })

        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
            const content = e.target.result
            const studentsArr = csvToArray(content)
            const existingStudents = getState().myClassrooms.students[classroomId]
            const validatedStudentsArr = validateStudentsArr(studentsArr, existingStudents)
            saveNewStudentsToFs(validatedStudentsArr, existingStudents, classroomId, (newStudentsObj) => {
                if (newStudentsObj instanceof Error) {
                    console.error("Error saving new students from csv: ", newStudentsObj);
                    dispatch({ type: "HIDE_SPINNER" })    
                }
                else {
                    const studentsInClass = { [classroomId]: newStudentsObj }  
                    dispatch({ type: "SET_STUDENTS_IN_CLASS", studentsInClass })
                    dispatch({ type: "HIDE_SPINNER" })
                }
            })
        }
    } 
}
const csvToArray = (content) => {
    const lines = content.split("\n")
    lines.shift()
    const newStudents = lines.map( (line) => {
        const lineContent = line.split(",")
        const nameFirst = lineContent[0].trim()
        const password = lineContent[1].trim()
        return {
            nameFirst: nameFirst,
            password: password
        }
    })
    return newStudents
}
const validateStudentsArr = (studentsArr, existingStudents) => {
    const existingStudentsArr = Object.values(existingStudents)
    const existingStudentsByPw = {}
    existingStudentsArr.forEach( (existingStudent) => {
        existingStudentsByPw[existingStudent.password] = true
    })
    const validatedStudentsArr = []
    studentsArr.forEach( (newStudent) => {
        if (existingStudentsByPw[newStudent.password]) { return }
        validatedStudentsArr.push(newStudent)
        existingStudentsByPw[newStudent.password] = true
    })
    return validatedStudentsArr
}
const saveNewStudentsToFs = (validatedStudentsArr, existingStudents, classroomId, callback) => {
    const batch = db.batch()
    let newStudentsObj = {}
    const dateCreated = new Date(0)
    validatedStudentsArr.forEach( (newStudent) => {
        const newStudentRef = db.collection("usersChild").doc()
        const studentIdNew = newStudentRef.id
        let newStudentToSave = {
            classroomId: classroomId,
            dateCreated: dateCreated,
            nameFirst: newStudent.nameFirst,
            password: newStudent.password
        }
        batch.set(newStudentRef, { ...newStudentToSave })
        newStudentsObj[studentIdNew] = {
            ...newStudentToSave,
            studentId: studentIdNew
        }
    })
    batch.commit()
        .then(() => {
            newStudentsObj = {
                ...newStudentsObj,
                ...existingStudents
            }
            callback(newStudentsObj)
        })
        .catch((error) => {
            callback(error)
        });
}



