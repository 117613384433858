import { initializeApp } from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// Initialize Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA2wZwPe1fpgd8AxZNrjQoDzPQsY0wqbOQ",
  authDomain: "ll-book-cl.firebaseapp.com",
  databaseURL: "https://ll-book-cl.firebaseio.com",
  projectId: "ll-book-cl",
  storageBucket: "ll-book-cl.appspot.com",
  messagingSenderId: "810661900105",
  appId: "1:810661900105:web:2c0af6f90a514befc33498",
  measurementId: "G-Q0BBYPNEM8"
};

const firebase = initializeApp(firebaseConfig);

export default firebase;
