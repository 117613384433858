import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getBooks, updateDays } from '../adminPages/actionsAdmin'

class UserOrders extends Component {
    state = {
        daysPurchased: {
            // [orderId]: days
        },
        dateExpiring: {
            // [orderId]: date
        },
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        const prevOrders = prevProps.state.adminData ?
            prevProps.state.adminData.orders ?
                prevProps.state.adminData.orders :
                null :
            null
        if (prevOrders === null) {
            const newOrders =
                this.props.state.adminData.orders ?
                    this.props.state.adminData.orders :
                    null
            if (newOrders !== null) {
                const daysPurchased = {}
                const dateExpiring = {}
                newOrders.forEach((order) => {
                    daysPurchased[order.orderId] = order.daysPurchased ? (order.daysPurchased).toString() : ""
                    dateExpiring[order.orderId] = order.expirationDate
                })
                this.setState({ daysPurchased, dateExpiring })
            }
        }
        else if (prevProps.state.adminData.userId !== this.props.state.adminData.userId) {
            const newOrders =
                this.props.state.adminData.orders ?
                    this.props.state.adminData.orders :
                    null
            const daysPurchased = {}
            const dateExpiring = {}
            newOrders.forEach((order) => {
                daysPurchased[order.orderId] = order.daysPurchased ? (order.daysPurchased).toString() : ""
                dateExpiring[order.orderId] = order.expirationDate
            })
            this.setState({ daysPurchased, dateExpiring })
        }
        else if (prevOrders.length !== this.props.state.adminData.orders.length) {
            const daysPurchased = {}
            const dateExpiring = {}
            this.props.state.adminData.orders.forEach((order) => {
                daysPurchased[order.orderId] = order.daysPurchased ? (order.daysPurchased).toString() : ""
                dateExpiring[order.orderId] = order.expirationDate
            })
            this.setState({ daysPurchased, dateExpiring })
        }
    }



    // Actions
    saveDays = (e) => {
        const orderId = e.target.id
        let daysPurchased = e.target.value

        const isNumber = /^\d+$/.test(daysPurchased)
        if (!isNumber && daysPurchased !== "") { return }

        const order = this.props.state.adminData.orders.find(order => order.orderId === orderId);
        let oldDaysPurchased = order.daysPurchased.toString()
        if (daysPurchased === oldDaysPurchased) {
            return 
        }

        this.commitDaysChange(orderId, daysPurchased)
    }

    saveDate = (e) => {
        const orderId = e.target.id
        const newExpiringString = e.target.value
        const order = this.props.state.adminData.orders.find(order => order.orderId === orderId);
        const oldExpiringString = order.expirationDate
        if (newExpiringString === oldExpiringString) { 
            return
        }
        
        const newExpiring = new Date(newExpiringString)
        const createDate = new Date(order.createDate)

        let daysPurchased = 0
        if (newExpiring > createDate) {
            const difference = newExpiring.getTime() - createDate.getTime();
            daysPurchased = Math.round(difference / (1000 * 3600 * 24));
        }

        this.commitDaysChange(orderId, daysPurchased, newExpiringString)
    }

    commitDaysChange = (orderId, daysPurchased, newExpiring) => {
        let expirationString = newExpiring
        if (newExpiring === undefined) {
            const order = this.props.state.adminData.orders.find(order => order.orderId === orderId);
            const createDate = new Date(order.createDate)
            const createDateMilliseconds = createDate.getTime()
            const expirationMilliseconds = createDateMilliseconds + (daysPurchased * 86400 * 1000)
            expirationString = new Date(expirationMilliseconds).toLocaleDateString("en-US")    
        }
        
        this.setState({
            daysPurchased: {
                ...this.state.daysPurchased,
                [orderId]: String(daysPurchased)
            },
            dateExpiring: {
                ...this.state.dateExpiring,
                [orderId]: String(expirationString)
            }
        })

        this.props.updateDays(orderId, daysPurchased)
    }

    changeDays = (e) => {
        const orderId = e.target.id
        const days = e.target.value
        this.setState({
            daysPurchased: {
                ...this.state.daysPurchased,
                [orderId]: days
            },
        })
    }

    changeDate = (e) => {
        const orderId = e.target.id
        const date = e.target.value
        this.setState({
            dateExpiring: {
                ...this.state.dateExpiring,
                [orderId]: date
            }
        })
    }

    // Render functions
    getOrderRows = () => {

        const orderRows = this.props.state.adminData.orders.map( (order) => {
            const days = this.state.daysPurchased[order.orderId] ? this.state.daysPurchased[order.orderId] : ""
            const orderDays = order.daysPurchased ? (order.daysPurchased).toString() : ""

            const dateExpiring = this.state.dateExpiring[order.orderId] ?? ""

            let styleDays = "input-myclassrooms input-uploadepub ao-days"
            if (days !== orderDays) {
                styleDays = "input-myclassrooms input-uploadepub input-mcnew"
            }

            let styleExpiration = "input-myclassrooms input-uploadepub ao-days"
            if (order.isExpired) {
                styleExpiration = "input-myclassrooms input-uploadepub ao-days ao-isexpired"
            }


            return (
                <tr key={order.orderId}>
                    <td className="col-35-body tdlastactivity-oneclassroom">
                        {order.book.title}
                    </td>
                    <td className="col-20-body tdlastactivity-oneclassroom">
                        {order.book.book_author}
                    </td>
                    <td className="col-15-body tdlastactivity-oneclassroom ao-truncate">
                        {order.howOrdered}
                    </td>
                    <td className="col-10-body tdlastactivity-oneclassroom">
                        {order.createDate}
                    </td>
                    <td className="col-10-body">
                        <input
                            className={styleDays}
                            type="text"
                            id={order.orderId}
                            onChange={this.changeDays}
                            onBlur={this.saveDays}
                            value={days}
                        />
                    </td>
                    <td className={"col-15-body"}>
                        <input
                            className={styleExpiration}
                            type="text"
                            id={order.orderId}
                            onChange={this.changeDate}
                            onBlur={this.saveDate}
                            value={dateExpiring}
                        />
                    </td>
                </tr>
            )
        });

        return orderRows

    }

    render(){
        if ( !this.props.state.adminData.orders ) return null
        return (
            <div>
                <div className="ao-orderstitle">
                    <span>Orders</span>
                    <span
                        className="ao-addorder"
                        onClick={this.props.clickedAddOrder}
                    >
                        Add Order
                    </span>
                </div>
                <div className="ao-tableWrap" >
                    <table className="table-oneclassroom">
                        <thead>
                            <tr>
                                <th className="col-35-header" >
                                    <span className="colhead-adminusers" > Book </span>
                                </th>
                                <th className="col-20-header" >
                                    <span className="colhead-adminusers" > Author </span>
                                </th>
                                <th className="col-15-header" >
                                    <span className="colhead-adminusers" > How </span>
                                </th>
                                <th className="col-10-header" >
                                    <span className="colhead-adminusers" > When </span>
                                </th>
                                <th className="col-10-header" >
                                    <span className="colhead-adminusers" > Days </span>
                                </th>
                                <th className="col-15-header" >
                                    <span className="colhead-adminusers" > Exp </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getOrderRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBooks: () =>  dispatch(getBooks()),
        updateDays: (orderId, daysPurchased) => dispatch(updateDays(orderId, daysPurchased))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOrders)

